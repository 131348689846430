import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { adsInjectorConfigs_template } from "../components/templates/adsInjectorConfigs_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adsInjectorConfigs_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected adsInjectorConfigs_template: adsInjectorConfigs_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "adsInjectorConfigs_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{
        
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ads Injector configs By Brand");

        // create the users form
        this.adsInjectorConfigs_template = new adsInjectorConfigs_template("adsInjectorConfigs_template", this.viewModel, this);

        return [this.adsInjectorConfigs_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{
        // this.getReferences();
        this.getEditData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveAdsInjectorConfigs(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelAdsInjectorConfigs(){

        this.loadingIn(this.adsInjectorConfigs_template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = `/brand/${idBrand}/ads_setups`

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Ads Injector configs action canceled!");

        page.redirect(url);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/adsInjectorConfigs/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let id = this.getVnAppZone().app.params.id || null;

        let url = `/api/brand/${idBrand}/adsInjectorConfigs/get/`;
        
        this.XHRHdl = new XMLHttpRequestHandler(url, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{
        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateEditData(responseParsed);
            });
        }
    }
/*
WONT NEED FOR NOW
    protected getReferences(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url = `/api/brand/${idBrand}/adsInjectorConfigs/references`
        
        this.loadingIn(this.adsInjectorConfigs_template.builtTemplate);
        this.XHRHdl = new XMLHttpRequestHandler(url, [], this);
        this.XHRHdl.onReadyStateFunction = this.onReferencesRequestReturn;
        this.XHRHdl.execute();
    }

    protected onReferencesRequestReturn(req, obj): Function {
        return function(){
            obj.manageResponse(req, obj, function(){
                
                let responseParsed = JSON.parse(req.request.response);
                obj.setGeos(responseParsed.geos);
                obj.setDefaultFloors(responseParsed.defaultFloors);
                obj.setAdsBehaviorDefaultAdsInjectorConfigs(responseParsed.adsBehaviorDefaultAdsInjectorConfigs);
                obj.loadingOut(obj.adsInjectorConfigs_template.builtTemplate);

                obj.getEditData();
            });
        }
    }
*/
    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){
        
        this.setAdsInjectorConfigs(responseParsed.data || []);
    }

    protected setAdsInjectorConfigs(adsInjectorConfigs){
        this.adsInjectorConfigs_template.setAdsInjectorConfigs(adsInjectorConfigs);
    }   

    /**
     * Saves the user data.
     */
    protected save(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url = `/api/brand/${idBrand}/adsInjectorConfigs/save`

        this.loadingIn(this.adsInjectorConfigs_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adsInjectorConfigs_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(url, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{
        return function(){

            let idBrand = obj.getVnAppZone().app.params.idBrand;
        
            let url = `/brand/${idBrand}/ads_setups`

            obj.manageResponse(req, obj, function() {
                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Ads Injector configss saved successfully!');
                obj.releaseLock();
                page.redirect(url);
            });

            obj.loadingOut(obj.adsInjectorConfigs_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
