import {vnModule} from '../../vendor/valnet/valnet_jslib/vnApp/vnModule';

import {home} from './home';
import {users_edit} from './users_edit';
import {users_list} from './users_list';
import {login} from './login';
import {menu_mod} from './menu';
import {header} from './header';
import {reset_password} from './reset_password';
import {data_import} from "./data_import";
import {vnAppZone} from '../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone';
import {users_profile} from './users_profile';
import {logs} from "../../vendor/valnet/valnet_jslib/vnApp/logs";
import {tasks} from '../../vendor/valnet/valnet_jslib/vnApp/tasks';
import {tasks_edit} from '../../vendor/valnet/valnet_jslib/vnApp/tasks_edit';
import {adsSetups} from './adsSetups';
import {adsSetup_edit} from './adsSetup_edit';
import {template_list} from './template_list';
import {template_edit} from './template_edit';
import {contentType_list} from './contentType_list';
import {contentType_edit} from './contentType_edit';
import {networkCategory_list} from './networkCategory_list';
import {networkCategory_edit} from './networkCategory_edit';
import {source_list} from './source_list';
import {source_edit} from './source_edit';
import {geo_list} from './geo_list';
import {geo_edit} from './geo_edit';
import {adType_list} from './adType_list';
import {adType_edit} from './adType_edit';
import {adTemplate_list} from './adTemplate_list';
import {adTemplate_edit} from './adTemplate_edit';
import {siteConfig} from './siteConfig';
import {adUnits} from './adUnits';
import {ssp_list} from './ssp_list';
import {ssp_edit} from './ssp_edit';
import {sspGroup_list} from './sspGroup_list';
import {sspGroup_edit} from './sspGroup_edit';
import {bidderType_list} from './bidderType_list';
import {bidderType_edit} from './bidderType_edit';
import {geoGroup_list} from './geoGroup_list';
import {geoGroup_edit} from './geoGroup_edit';
import {adHealth} from "./adHealth";
import {adUnit_list} from "./adUnit_list";
import {templateType_list} from './templateType_list';
import {templateType_edit} from './templateType_edit';
import {templateTypeDependencies} from './templateTypeDependencies';
import {authorizedDigitalSellers} from './authorizedDigitalSellers';
import {sharedHeader} from './sharedHeader';
import {adZone_list} from "./adZone_list";
import {adZone_edit} from "./adZone_edit";
import {sspConfig_edit} from './sspConfig_edit';
import {performanceSummary} from "./performanceSummary";
import {roles} from "../../vendor/valnet/valnet_jslib/vnApp/roles";
import {roles_edit} from "../../vendor/valnet/valnet_jslib/vnApp/roles_edit";
import {roles_view} from "../../vendor/valnet/valnet_jslib/vnApp/roles_view";
import {prebid_sspSetup_add} from "./prebid_sspSetup_add";
import {globalCss} from "./globalCss";
import {prebidConfigurator} from "./prebidConfigurator";
import {repeatableAdInjectionContentToIgnore} from "./repeatableAdInjectionContentToIgnore";
import {bidderTypeLibrary_list} from "./bidderTypeLibrary_list";
import {bidderTypeLibrary_edit} from "./bidderTypeLibrary_edit";
import {adsSetupTag_list} from "./adsSetupTag_list";
import {adsSetupTag_edit} from "./adsSetupTag_edit";
import {realTimeStats} from "./realTimeStats";
import {articleLevelSV} from "./articleLevelSV";
import {adDisplayType} from "./adDisplayType";
import {adRevenue} from "./adRevenue";
import {advertisingPartner_list} from "./advertisingPartner_list";
import {advertisingPartner_edit} from "./advertisingPartner_edit";
import {advertisingPartnerAccess_list} from "./advertisingPartnerAccess_list";
import {advertisingPartnerAccess_edit} from "./advertisingPartnerAccess_edit";
import {advertisingPartnerByBrand} from "./advertisingPartnerByBrand";
import {brand_list} from "./brand_list";
import {brand_edit} from "./brand_edit";
import {adsBehaviourByBrand} from "./adsBehaviourByBrand";
import {videoPrebidFloors} from "./videoPrebidFloors";
import {gamAccount_list} from "./gamAccount_list";
import {gamAccount_edit} from "./gamAccount_edit";
import {sponsoredRevenue} from "./sponsoredRevenue";
import {sponsoredRevenueEdit} from "./sponsoredRevenueEdit";
import {connectionConfig} from "./connectionConfig";
import { revenueSheet } from './revenueSheet';
import {abTestingSchedule_edit} from "./abTestingSchedule_edit";
import {adBehaviours_highValueSegment_edit} from "./adBehaviours_highValueSegment_edit";
import {adsInjectorConfigs_edit} from "./adsInjectorConfigs_edit";

export class pageFactory{
    get_actionHandler(typeName :string, vnAppZone: vnAppZone) :vnModule{
        
        let obj :vnModule;
        console.log(`Instantiating module [${typeName}]`);

        switch(typeName){

            case 'home':
                obj = new home(vnAppZone);
                break;
            case 'users':
                obj = new users_list(vnAppZone);
                break;
            case 'users_edit':
                obj = new users_edit(vnAppZone);
                break;
            case 'users_profile':
                obj = new users_profile(vnAppZone);
                break;
            case 'login':
                obj = new login(vnAppZone);
                break;
            case 'menu':
                obj = new menu_mod(vnAppZone);
                break;
            case 'header':
                obj = new header(vnAppZone);
                break;
            case 'reset_password':
                obj = new reset_password(vnAppZone);
                break;
            case "data_import" :
                obj = new data_import(vnAppZone);
                break;
            case 'logs':
                obj = new logs(vnAppZone);
                break;
            case "tasks" :
                obj = new tasks(vnAppZone);
                break;
            case "tasks_edit" :
                obj = new tasks_edit(vnAppZone);
                break;
            case "adsSetups" :
                obj = new adsSetups(vnAppZone);
                break;
            case "adsSetups_edit" :
                obj = new adsSetup_edit(vnAppZone);
                break;
            case "template_list" :
                obj = new template_list(vnAppZone);
                break;
            case "template_edit" :
                obj = new template_edit(vnAppZone);
                break;
            case "contentType_list" :
                obj = new contentType_list(vnAppZone);
                break;
            case "contentType_edit" :
                obj = new contentType_edit(vnAppZone);
                break;
            case "networkCategory_list" :
                obj = new networkCategory_list(vnAppZone);
                break;
            case "networkCategory_edit" :
                obj = new networkCategory_edit(vnAppZone);
                break;
            case "prebid_sspSetup_add":
                obj = new prebid_sspSetup_add(vnAppZone);
                break;
            case "source_list" :
                obj = new source_list(vnAppZone);
                break;
            case "source_edit" :
                obj = new source_edit(vnAppZone);
                break;
            case "geo_list" :
                obj = new geo_list(vnAppZone);
                break;
            case "geo_edit" :
                obj = new geo_edit(vnAppZone);
                break;
            case "adType_list" :
                obj = new adType_list(vnAppZone);
                break;
            case "adType_edit" :
                obj = new adType_edit(vnAppZone);
                break;
            case "adTemplate_list" :
                obj = new adTemplate_list(vnAppZone);
                break;
            case "adTemplate_edit" :
                obj = new adTemplate_edit(vnAppZone);
                break;
            case "siteConfig" :
                obj = new siteConfig(vnAppZone);
                break;
            case "adUnits" :
                obj = new adUnits(vnAppZone);
                break;
            case "ssp_list" :
                obj = new ssp_list(vnAppZone);
                break;
            case "ssp_edit" :
                obj = new ssp_edit(vnAppZone);
                break;
            case "sspGroup_list" :
                obj = new sspGroup_list(vnAppZone);
                break;
            case "sspGroup_edit" :
                obj = new sspGroup_edit(vnAppZone);
                break;
            case "bidderType_list" :
                obj = new bidderType_list(vnAppZone);
                break;
            case "bidderType_edit" :
                obj = new bidderType_edit(vnAppZone);
                break;
            case "adHealth" :
                obj = new adHealth(vnAppZone);
                break;
            case "performanceSummary" :
                obj = new performanceSummary(vnAppZone);
                break;
            case "geoGroup_list" :
                obj = new geoGroup_list(vnAppZone);
                break;
            case "geoGroup_edit" :
                obj = new geoGroup_edit(vnAppZone);
                break;
            case "adUnit_list" :
                obj = new adUnit_list(vnAppZone);
                break;
            case "templateType_list" :
                obj = new templateType_list(vnAppZone);
                break;
            case "templateType_edit" :
                obj = new templateType_edit(vnAppZone);
                break;
            case "templateTypeDependencies" :
                obj = new templateTypeDependencies(vnAppZone);
                break;
            case "ads.txt" :
                obj = new authorizedDigitalSellers(vnAppZone);
                break;
            case "sharedHeader" :
                obj = new sharedHeader(vnAppZone);
                break;

            case "adZone_list":
                obj = new adZone_list(vnAppZone);
                break;

            case "adZone_edit":
                obj = new adZone_edit(vnAppZone);
                break;

            case "sspConfig_edit":
                obj = new sspConfig_edit(vnAppZone);
                break;
            case "globalCss":
                obj = new globalCss(vnAppZone);
                break;

            case "roles" :
                obj = new roles(vnAppZone);
                break;

            case "roles_edit" :
                obj = new roles_edit(vnAppZone);
                break;

            case "roles_view" :
                obj = new roles_view(vnAppZone);
                break;

            case "prebidConfigurator" :
                obj = new prebidConfigurator(vnAppZone);
                break;

            case "repeatableAdInjectionContentToIgnore":
                obj = new repeatableAdInjectionContentToIgnore(vnAppZone);
                break;

            case "bidderTypeLibrary_list":
                obj = new bidderTypeLibrary_list(vnAppZone);
                break;

            case "bidderTypeLibrary_edit":
                obj = new bidderTypeLibrary_edit(vnAppZone);
                break;

            case "adsSetupTag_list":
                obj = new adsSetupTag_list(vnAppZone);
                break;

            case "adsSetupTag_edit":
                obj = new adsSetupTag_edit(vnAppZone);
                break;

            case "realTimeStats":
                obj = new realTimeStats(vnAppZone);
                break;

            case "articleLevelSV":
                obj = new articleLevelSV(vnAppZone);
                break;

            case "adDisplayType":
                obj = new adDisplayType(vnAppZone);
                break;

            case "adRevenue":
                obj = new adRevenue(vnAppZone);
                break;

            case "advertisingPartner_list":
                obj = new advertisingPartner_list(vnAppZone)
                break;
            case "advertisingPartner_edit":
                obj = new advertisingPartner_edit(vnAppZone)
                break;                
                
            case "advertisingPartnerAccess_list":
                obj = new advertisingPartnerAccess_list(vnAppZone)
                break;
            case "advertisingPartnerAccess_edit":
                obj = new advertisingPartnerAccess_edit(vnAppZone)
                break;            
                
            case "advertisingPartner":
                obj = new advertisingPartnerByBrand(vnAppZone)
                break;            
            case "brand_edit":
                obj = new brand_edit(vnAppZone)
                break;            
            case "brand_list":
                obj = new brand_list(vnAppZone)
                break;            
            case "adsBehaviourByBrand":
                obj = new adsBehaviourByBrand(vnAppZone)
                break;             
            case "videoPrebidFloors":
                obj = new videoPrebidFloors(vnAppZone)
                break;            
            case "gamAccount_list":
                obj = new gamAccount_list(vnAppZone)
                break;            
            case "gamAccount_edit":
                obj = new gamAccount_edit(vnAppZone)
                break;
            case "sponsoredRevenue" :
                obj = new sponsoredRevenue(vnAppZone);
                break;
            case "sponsoredRevenueEdit" :
                obj = new sponsoredRevenueEdit(vnAppZone);
                break;
            case "revenueSheet":
                obj = new revenueSheet(vnAppZone)
                break;
            case "connectionConfig" :
                obj = new connectionConfig(vnAppZone);
                break;
            case "abTestingSchedule_edit" :
                obj = new abTestingSchedule_edit(vnAppZone);
                break;
            case "adBehaviours_highValueSegment_edit":
                obj = new adBehaviours_highValueSegment_edit(vnAppZone);
                break;            
            case "adsInjectorConfigs_edit":
                obj = new adsInjectorConfigs_edit(vnAppZone);
                break;
        }

        return obj;
    }
}
