import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { connectionConfig_template } from "../components/templates/connectionConfig_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class connectionConfig extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected connectionConfig_template: connectionConfig_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "connectionConfig";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Config Edit");

        // create the users form
        this.connectionConfig_template = new connectionConfig_template("connectionConfig_template", this.viewModel, this);

        return [this.connectionConfig_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getReferences();
    }

    /**
     * Hook.
     *
     * Saves the edits
     */
    protected saveConnectionConfig(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits
     */
    protected cancelConnectionConfig(){

        this.loadingIn(this.connectionConfig_template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = '/configurations';
        if (idBrand) {
            url = `/brand/${idBrand}/ads_setups`;
        }


        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Connection config action canceled!");

        page.redirect(url);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url  = '/api/connection_config/release_lock/-1';
        if (idBrand) {
            url = `/api/brand/${idBrand}/connection_config/release_lock/`;
        }

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(url, [], this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.connectionConfig_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = '/api/connection_config/get';
        if (idBrand) {
            url = `/api/brand/${idBrand}/connection_config/get`;
        }

        this.XHRHdl = new XMLHttpRequestHandler(url, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.connectionConfig_template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){
        if(responseParsed.data !== null) {
            this.setConnectionConfig(responseParsed.data);
        }

        if (responseParsed.globalConfig !== null && responseParsed.globalConfig !== undefined) {
            this.setGlobalConfig(responseParsed.globalConfig);
        } else {
            this.setGlobalConfig(null);
        }
    }

    protected setConnectionConfig(connectionConfig){

        this.connectionConfig_template.setConnectionConfig(connectionConfig);
    }

    protected setGlobalConfig(globalConfig){

        this.connectionConfig_template.setGlobalConfig(globalConfig);
    }


    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.connectionConfig_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.connectionConfig_template.getViewModelForRequest();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let url = '/api/connection_config/save/';
        if (idBrand) {
            url = `/api/brand/${idBrand}/connection_config/save/`;
        }

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(url, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            let idBrand = obj.getVnAppZone().app.params.idBrand;
        
            let url     = '';
            if(idBrand){
                url = `/brand/${idBrand}/ads_setups`
            }
            else{
                url = `/configurations`
            }

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Connection config saved successfully!');
                obj.releaseLock();
                page.redirect(url);
            });

            obj.loadingOut(obj.connectionConfig_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getReferences(): void{

        this.loadingIn(this.connectionConfig_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = '/api/connection_config/references/';
        if (idBrand) {
            url = `/api/brand/${idBrand}/connection_config/references/`;
        }

        this.XHRHdl = new XMLHttpRequestHandler(url, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.setIdBrand(responseParsed.idBrand);
                obj.setConnectionTypes(responseParsed.connectionTypes);
                obj.loadingOut(obj.connectionConfig_template.builtTemplate);
                // obj.getEditData();
            });
        }
    }

    protected setConnectionTypes(connectionTypes){

        this.connectionConfig_template.setConnectionTypes(connectionTypes);
    }    
    
    protected setIdBrand(idBrand){

        this.connectionConfig_template.setIdBrand(idBrand);
    }

    protected changeConnectionType(connectionType, idBrand): void{
        if (!connectionType) {
            return;
        }
        
        this.loadingIn(this.connectionConfig_template.builtTemplate);

        let url = `/api/connection_config/get/`;
        if (idBrand) {
            url = `/api/brand/${idBrand}/connection_config/get/`;
        }

        this.XHRHdl = new XMLHttpRequestHandler(url, [['connectionType', connectionType]], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();
    }
}
