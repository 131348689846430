import { vnAppZone } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone";
import { pageFactory } from "../modules/factory";

export class vnAppZoneContent extends vnAppZone {

    protected factory: pageFactory;

    public constructor(destination) {
        super(destination);
        this.zoneName = "Content";

        this.isOnAlwaysReloadMode = true;
        this.factory = new pageFactory();
    }


    protected defineRoutes_getModule(moduleKey: string){
        let _mythis = this;
        return function(){
            // obtain the right module object
            let moduleObj = _mythis.factory.get_actionHandler(moduleKey, _mythis);

            // load it into the zone
            _mythis.loadModule(moduleObj);
        }

    }

    defineRoutes() {
        let _mythis = this;

        //define routes
        this.addForRoute('/', _mythis.defineRoutes_getModule("home"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/home', _mythis.defineRoutes_getModule("home"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/home', _mythis.defineRoutes_getModule("adsSetups"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/login', _mythis.defineRoutes_getModule("login"), null );
        this.addForRoute('/reset/token/:token', _mythis.defineRoutes_getModule("reset_password"), null );
        this.addForRoute('/users/edit/:id', _mythis.defineRoutes_getModule("users_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/users/profile/:id', _mythis.defineRoutes_getModule("users_profile"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/users/add', _mythis.defineRoutes_getModule("users_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/users', _mythis.defineRoutes_getModule("users"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/data_import', _mythis.defineRoutes_getModule("data_import"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/logs', _mythis.defineRoutes_getModule("logs"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/topics', _mythis.defineRoutes_getModule("topics"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/topics/add', _mythis.defineRoutes_getModule("topics_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/topics/edit/:id', _mythis.defineRoutes_getModule("topics_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/posts', _mythis.defineRoutes_getModule("articles"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/post/add', _mythis.defineRoutes_getModule("articles_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/post/edit/:id', _mythis.defineRoutes_getModule("articles_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/tags', _mythis.defineRoutes_getModule("tags"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/tag/add', _mythis.defineRoutes_getModule("tags_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/tag/edit/:id', _mythis.defineRoutes_getModule("tags_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/payment/transactions/list', _mythis.defineRoutes_getModule("transactions"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/transaction/add', _mythis.defineRoutes_getModule("transactions_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/transaction/edit/:id', _mythis.defineRoutes_getModule("transactions_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/transaction/delete/:id', _mythis.defineRoutes_getModule("transactions_delete"), _mythis.defineRoutes_getModule("login"));
        this.addForRoute('/payment/transactions/payments', _mythis.defineRoutes_getModule("global_payment"), _mythis.defineRoutes_getModule("login"));
        this.addForRoute('/payment/documents/slip', _mythis.defineRoutes_getModule("transactions_slip"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/payment/documents/slip/:userId', _mythis.defineRoutes_getModule("transactions_slip_detail"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/documents/summary', _mythis.defineRoutes_getModule("transactions_payment_summary"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/documents/summary-printable/:paymentId', _mythis.defineRoutes_getModule("transactions_payment_summary_printable"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/documents/accounting-report/', _mythis.defineRoutes_getModule("accounting_report"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/payment/transactions/recurring/list/', _mythis.defineRoutes_getModule("transactions_recurring"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/payment/transactions/recurring/add', _mythis.defineRoutes_getModule("transactions_recurring_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/payment/transactions/recurring/edit/:id', _mythis.defineRoutes_getModule("transactions_recurring_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/payment/transactions/verification/list/', _mythis.defineRoutes_getModule("transactions_verification"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/articles/', _mythis.defineRoutes_getModule("articles"), _mythis.defineRoutes_getModule("login") );

        // this is for article_add
        this.addForRoute('/brand/:idBrand/articles/add/', _mythis.defineRoutes_getModule("articles_add"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/articles/edit/:id', _mythis.defineRoutes_getModule("articles_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/articles/view/:id', _mythis.defineRoutes_getModule("articles_view"), _mythis.defineRoutes_getModule("login") );

        // these have been replaced with new format
        this.addForRoute('/payment/transactions/list', _mythis.defineRoutes_getModule("transactions"), _mythis.defineRoutes_getModule("login"));

        this.addForRoute('/accounting-report', _mythis.defineRoutes_getModule("centralized_accounting_report"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/:idBrand/home', _mythis.defineRoutes_getModule("home"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/:idBrand/payment/transactions/list', _mythis.defineRoutes_getModule("transactions"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/:idBrand/transaction/add', _mythis.defineRoutes_getModule("transactions_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/:idBrand/payment/transaction/edit/:id', _mythis.defineRoutes_getModule("transactions_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/:idBrand/payment/transaction/delete/:id', _mythis.defineRoutes_getModule("transactions_delete"), _mythis.defineRoutes_getModule("login"));
        this.addForRoute('/:idBrand/payment/documents/accounting-report/', _mythis.defineRoutes_getModule("projects_total_cost"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/tasks', _mythis.defineRoutes_getModule("tasks"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/tasks/add', _mythis.defineRoutes_getModule("tasks_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/reports', _mythis.defineRoutes_getModule("home"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ad_health', _mythis.defineRoutes_getModule("adHealth"), _mythis.defineRoutes_getModule("login"));
        this.addForRoute('/performance_summary', _mythis.defineRoutes_getModule("performanceSummary"), _mythis.defineRoutes_getModule("login"));

        this.addForRoute('/brand/:idBrand/ads_setups', _mythis.defineRoutes_getModule("adsSetups"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ads_setups/add', _mythis.defineRoutes_getModule("adsSetups_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ads_setups/edit/:id', _mythis.defineRoutes_getModule("adsSetups_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/templates', _mythis.defineRoutes_getModule("template_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/templates/add', _mythis.defineRoutes_getModule("template_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/templates/edit/:id', _mythis.defineRoutes_getModule("template_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/content_types', _mythis.defineRoutes_getModule("contentType_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/content_types/add', _mythis.defineRoutes_getModule("contentType_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/content_types/edit/:id', _mythis.defineRoutes_getModule("contentType_edit"), _mythis.defineRoutes_getModule("login") );    

        this.addForRoute('/network_categories', _mythis.defineRoutes_getModule("networkCategory_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/network_categories/add', _mythis.defineRoutes_getModule("networkCategory_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/network_categories/edit/:id', _mythis.defineRoutes_getModule("networkCategory_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/sources', _mythis.defineRoutes_getModule("source_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/sources/add', _mythis.defineRoutes_getModule("source_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/sources/edit/:id', _mythis.defineRoutes_getModule("source_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/geos', _mythis.defineRoutes_getModule("geo_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/geos/add', _mythis.defineRoutes_getModule("geo_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/geos/edit/:id', _mythis.defineRoutes_getModule("geo_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/ad_types', _mythis.defineRoutes_getModule("adType_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ad_types/add', _mythis.defineRoutes_getModule("adType_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ad_types/edit/:id', _mythis.defineRoutes_getModule("adType_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/ad_templates', _mythis.defineRoutes_getModule("adTemplate_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ad_templates/add', _mythis.defineRoutes_getModule("adTemplate_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ad_templates/edit/:id', _mythis.defineRoutes_getModule("adTemplate_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/site_config', _mythis.defineRoutes_getModule("siteConfig"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/ads_setups/:id/adUnits', _mythis.defineRoutes_getModule("adUnits"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ad_units', _mythis.defineRoutes_getModule("adUnit_list"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/ssps', _mythis.defineRoutes_getModule("ssp_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ssps/add', _mythis.defineRoutes_getModule("ssp_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ssps/edit/:id', _mythis.defineRoutes_getModule("ssp_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/ssps/', _mythis.defineRoutes_getModule("ssp_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ssps/:idSsp/config/edit/', _mythis.defineRoutes_getModule("sspConfig_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/ssp_groups', _mythis.defineRoutes_getModule("sspGroup_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ssp_groups/add', _mythis.defineRoutes_getModule("sspGroup_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ssp_groups/edit/:id', _mythis.defineRoutes_getModule("sspGroup_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/bidder_types', _mythis.defineRoutes_getModule("bidderType_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/bidder_types/add', _mythis.defineRoutes_getModule("bidderType_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/bidder_types/edit/:id', _mythis.defineRoutes_getModule("bidderType_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/geo_groups', _mythis.defineRoutes_getModule("geoGroup_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/geo_groups/add', _mythis.defineRoutes_getModule("geoGroup_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/geo_groups/edit/:id', _mythis.defineRoutes_getModule("geoGroup_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/ads_setups/:idAdsSetup/zones/:idZone/edit', _mythis.defineRoutes_getModule("adsSetupZoneSettings_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/template_types', _mythis.defineRoutes_getModule("templateType_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/template_types/add', _mythis.defineRoutes_getModule("templateType_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/template_types/edit/:id', _mythis.defineRoutes_getModule("templateType_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/template_type_dependencies', _mythis.defineRoutes_getModule("templateTypeDependencies"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/ads.txt', _mythis.defineRoutes_getModule("ads.txt"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/shared_header', _mythis.defineRoutes_getModule("sharedHeader"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/configurations', _mythis.defineRoutes_getModule("home"), _mythis.defineRoutes_getModule("login") );


        this.addForRoute('/brand/:idBrand/zones', _mythis.defineRoutes_getModule("adZone_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/zones/add', _mythis.defineRoutes_getModule("adZone_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/zones/edit/:id', _mythis.defineRoutes_getModule("adZone_edit"), _mythis.defineRoutes_getModule("login") );

        // Roles routes.
        this.addForRoute('/roles/edit', _mythis.defineRoutes_getModule("roles_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/roles/edit/:id', _mythis.defineRoutes_getModule("roles_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/roles/view/:id', _mythis.defineRoutes_getModule("roles_view"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/roles', _mythis.defineRoutes_getModule("roles"), _mythis.defineRoutes_getModule("login") );

        //Prebid SSP setup routes.
        this.addForRoute('/prebid/ssp_setup/add', _mythis.defineRoutes_getModule("prebid_sspSetup_add"), _mythis.defineRoutes_getModule("login") );

        //Global CSS route
        this.addForRoute('/global_css/edit', _mythis.defineRoutes_getModule('globalCss'), _mythis.defineRoutes_getModule("login"));

        // Prebid configurator.
        this.addForRoute('/brand/:idBrand/bidder_type_library/prebidConfigurator/edit/:id', _mythis.defineRoutes_getModule("prebidConfigurator"), _mythis.defineRoutes_getModule("login") );

        // Repeatable ad injection content to ignore.
        this.addForRoute('/brand/:idBrand/repeatableAdInjectionContentToIgnore', _mythis.defineRoutes_getModule("repeatableAdInjectionContentToIgnore"), _mythis.defineRoutes_getModule("login") );

        // Bidder type library.
        this.addForRoute('/brand/:idBrand/bidder_type_library', _mythis.defineRoutes_getModule("bidderTypeLibrary_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/bidder_type_library/add', _mythis.defineRoutes_getModule("bidderTypeLibrary_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/bidder_type_library/edit/:id', _mythis.defineRoutes_getModule("bidderTypeLibrary_edit"), _mythis.defineRoutes_getModule("login") );

        // Ads setup tag.
        this.addForRoute('/ads_setup_tag', _mythis.defineRoutes_getModule("adsSetupTag_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ads_setup_tag/add', _mythis.defineRoutes_getModule("adsSetupTag_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ads_setup_tag/edit/:id', _mythis.defineRoutes_getModule("adsSetupTag_edit"), _mythis.defineRoutes_getModule("login") );

        // Real time stats.
        this.addForRoute('/brand/:idBrand/video_player_stats', _mythis.defineRoutes_getModule("realTimeStats"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/video_player_stats', _mythis.defineRoutes_getModule("realTimeStats"), _mythis.defineRoutes_getModule("login") );

        // Article-level SV
        this.addForRoute('/article_level_sv', _mythis.defineRoutes_getModule("articleLevelSV"), _mythis.defineRoutes_getModule("login") );

        // Ad Display Type
        this.addForRoute('/ad_display_type', _mythis.defineRoutes_getModule("adDisplayType"), _mythis.defineRoutes_getModule("login") );

        // Video Player Revenue
        this.addForRoute('/ad_revenue', _mythis.defineRoutes_getModule("adRevenue"), _mythis.defineRoutes_getModule("login") );

        // Revenue Sheet
        this.addForRoute('/revenue_sheet', _mythis.defineRoutes_getModule("revenueSheet"), _mythis.defineRoutes_getModule("login") );

        // Brands
        this.addForRoute('/brand', _mythis.defineRoutes_getModule("brand_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/add', _mythis.defineRoutes_getModule("brand_edit"), _mythis.defineRoutes_getModule("login") );
        // this.addForRoute('/brand/edit/:id', _mythis.defineRoutes_getModule("brand_edit"), _mythis.defineRoutes_getModule("login") );

        //Advertising partners
        this.addForRoute('/advertising_partners', _mythis.defineRoutes_getModule("advertisingPartner_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/advertising_partners/add', _mythis.defineRoutes_getModule("advertisingPartner_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/advertising_partners/edit/:id', _mythis.defineRoutes_getModule("advertisingPartner_edit"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/brand/:idBrand/advertising_partners', _mythis.defineRoutes_getModule("advertisingPartner"), _mythis.defineRoutes_getModule("login") );

        this.addForRoute('/advertising_partners/access', _mythis.defineRoutes_getModule("advertisingPartnerAccess_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/advertising_partners/access/add', _mythis.defineRoutes_getModule("advertisingPartnerAccess_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/advertising_partners/access/edit/:id', _mythis.defineRoutes_getModule("advertisingPartnerAccess_edit"), _mythis.defineRoutes_getModule("login") );

        //Ads behaviour
        this.addForRoute('/brand/:idBrand/ads_behaviour', _mythis.defineRoutes_getModule("adsBehaviourByBrand"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/ads_behaviour', _mythis.defineRoutes_getModule("adsBehaviourByBrand"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/ads_behaviour/high_value', _mythis.defineRoutes_getModule("adBehaviours_highValueSegment_edit"), _mythis.defineRoutes_getModule("login") );
        
        //Video Prebid Floor
        this.addForRoute('/brand/:idBrand/video_prebid_floors', _mythis.defineRoutes_getModule("videoPrebidFloors"), _mythis.defineRoutes_getModule("login") );       
        
        //Ads Injector Config
        this.addForRoute('/brand/:idBrand/ads_injector_configs', _mythis.defineRoutes_getModule("adsInjectorConfigs_edit"), _mythis.defineRoutes_getModule("login") );

        //GAM
        this.addForRoute('/gam/account', _mythis.defineRoutes_getModule("gamAccount_list"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/gam/account/add', _mythis.defineRoutes_getModule("gamAccount_edit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/gam/account/edit/:id', _mythis.defineRoutes_getModule("gamAccount_edit"), _mythis.defineRoutes_getModule("login") );

        // Sponsored Revenue routes
        this.addForRoute('/sponsored_revenue', _mythis.defineRoutes_getModule("sponsoredRevenue"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/sponsored_revenue/add', _mythis.defineRoutes_getModule("sponsoredRevenueEdit"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/sponsored_revenue/edit/:id', _mythis.defineRoutes_getModule("sponsoredRevenueEdit"), _mythis.defineRoutes_getModule("login") );

        //Connection Config Routes
        this.addForRoute('/connection_config', _mythis.defineRoutes_getModule("connectionConfig"), _mythis.defineRoutes_getModule("login") );
        this.addForRoute('/brand/:idBrand/connection_config', _mythis.defineRoutes_getModule("connectionConfig"), _mythis.defineRoutes_getModule("login") );

        // A/B Testing
        this.addForRoute('/brand/:idBrand/abTestingSchedule', _mythis.defineRoutes_getModule("abTestingSchedule_edit"), _mythis.defineRoutes_getModule("login") );
    }
}

