import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { videoPrebidFloors_template } from "../components/templates/videoPrebidFloors_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class videoPrebidFloors extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected videoPrebidFloors_template: videoPrebidFloors_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "videoPrebidFloors";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{
        
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Video prebid floor By Brand");

        // create the users form
        this.videoPrebidFloors_template = new videoPrebidFloors_template("videoPrebidFloors_template", this.viewModel, this);

        return [this.videoPrebidFloors_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{
        this.getReferences();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveVideoPrebidFloor(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelvideoPrebidFloor(){

        this.loadingIn(this.videoPrebidFloors_template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = `/brand/${idBrand}/ads_setups`

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Video prebid floor action canceled!");

        page.redirect(url);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/video_prebid_floors/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let id = this.getVnAppZone().app.params.id || null;

        let url = `/api/brand/${idBrand}/video_prebid_floors/get/`;
        
        this.XHRHdl = new XMLHttpRequestHandler(url, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{
        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateEditData(responseParsed);
            });
        }
    }

    protected getReferences(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url = `/api/brand/${idBrand}/video_prebid_floors/references`
        
        this.loadingIn(this.videoPrebidFloors_template.builtTemplate);
        this.XHRHdl = new XMLHttpRequestHandler(url, [], this);
        this.XHRHdl.onReadyStateFunction = this.onReferencesRequestReturn;
        this.XHRHdl.execute();
    }

    protected onReferencesRequestReturn(req, obj): Function {
        return function(){
            obj.manageResponse(req, obj, function(){
                
                let responseParsed = JSON.parse(req.request.response);
                obj.setGeos(responseParsed.geos);
                obj.setDefaultFloors(responseParsed.defaultFloors);
                obj.setAdsBehaviorDefaultVideoPrebidFloor(responseParsed.adsBehaviorDefaultVideoPrebidFloor);
                obj.loadingOut(obj.videoPrebidFloors_template.builtTemplate);

                obj.getEditData();
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){
        
        this.setVideoPrebidFloors(responseParsed.data || []);
    }

    protected setVideoPrebidFloors(videoPrebidFloors){
        this.videoPrebidFloors_template.setVideoPrebidFloors(videoPrebidFloors);
    }

    protected setGeos(geos){
        this.videoPrebidFloors_template.setGeos(geos);
    }        
    
    protected setDefaultFloors(defaultFloors){
        this.videoPrebidFloors_template.setDefaultFloors(defaultFloors);
    }    
    
    protected setAdsBehaviorDefaultVideoPrebidFloor(adsBehaviorDefaultVideoPrebidFloor){
        this.videoPrebidFloors_template.setDefaultVideoPrebidFloor(adsBehaviorDefaultVideoPrebidFloor);
    }



    /**
     * Saves the user data.
     */
    protected save(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url = `/api/brand/${idBrand}/video_prebid_floors/save`

        this.loadingIn(this.videoPrebidFloors_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.videoPrebidFloors_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(url, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{
        return function(){

            let idBrand = obj.getVnAppZone().app.params.idBrand;
        
            let url = `/brand/${idBrand}/ads_setups`

            obj.manageResponse(req, obj, function() {
                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Video prebid floors saved successfully!');
                obj.releaseLock();
                page.redirect(url);
            });

            obj.loadingOut(obj.videoPrebidFloors_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    public filterMultipleSelectorOnChange(): void {
        //needed for multiple selector
    }
}
