import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class adsInjectorConfigs_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-txt">Edit Ads Injector configs File</h3>

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group">
                        <textarea id="configs" data-bind="value: configs" class="input-text long extra"></textarea>
                    </div>
                </div>
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adsInjectorConfigs-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            id: ko.observable(''),
            configs: ko.observable(""),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel         = this.getTemplateViewModel();

        return {
            id : viewModel.id(),
            configs : viewModel.configs()
        };
    }

    public setAdsInjectorConfigs(adsInjectorConfigs): void {
        console.log(adsInjectorConfigs)
        this.getTemplateViewModel().id(adsInjectorConfigs.id);
        this.getTemplateViewModel().configs(adsInjectorConfigs.configs);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveAdsInjectorConfigs();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelAdsInjectorConfigs();
    }

}
