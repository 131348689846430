import {vnModule_knockoutJS} from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import {menuNav_template} from "../components/templates/menuNav_template";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";

declare var ko: any;
declare var page: any;

export class menu_mod extends vnModule_knockoutJS {

    protected menu: menuNav_template;

    protected getModuleName() {
        return "main-nav";
    }

    buildContent(): Array<Element>{

        // Create the menu template object
        this.menu           = new menuNav_template("mainMenu", this.viewModel, this);
        let showMenuSidebar = this.getShowMenuSidebar();

        if(null == this.getShowMenuSidebar() || showMenuSidebar){

            // this.setShowMenuSidebar(true);

            let body = document.getElementsByTagName("BODY")[0];

            if(body){

                body.classList.add('is-open');
            }

            this.menu.getTemplateViewModel().menuToggleClasses('is-open');
        }

        // Following action are only executed if the user is logged in
        let vnApp = this.getVnAppZone().app;
        if (vnApp.loggedUser != null && vnApp.loggedUser.isLoggedIn) {

            this.setContextBrand();
            this.menu.setIsUserLoggedIn(true);
            this.menu.setBrands(vnApp.loggedUser.user.brands);

            let showBrandSelector = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_MENU_SHOW_BRANDS);
            if (!showBrandSelector){
                this.menu.getTemplateViewModel().showBrandSelector(false);
            }

            // If a brand is selected, setup the menu's options

            let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
            let loadedModule = zoneHeader.loadedModule;

            if (this.getVnAppZone().getGlobal("idBrand")) {

                let idBrand = this.getVnAppZone().getGlobal("idBrand");

                for (let i of vnApp.loggedUser.user.brands) {
                    if( i.id==idBrand){
                        (<header>loadedModule).setBrandClass('prop ' + i.iconImage);
                        (<header>loadedModule).setBrandName(i.name);
                    }
                }

                this.menu.setSelectedBrand(idBrand);
                this.menu.setMenus(this.updateMenuListItemWithBrandSelection(idBrand));
            } else {

                let menuList = this.updateMenuListItemWithBrandSelection('');

                // Hide the brand selector.
                if(!menuList[0].visible){

                    this.menu.getTemplateViewModel().showBrandSelector(false);
                }

                this.menu.setMenus(menuList);
                (<header>loadedModule).setBrandClass("");
                (<header>loadedModule).setBrandName("");
            }
        }else{
            console.log('here1');
            this.menu.setSelectedBrand(null);
            this.menu.setBrands([]);
        }

        return [this.menu.build()];
    }

    public fillPage(): void {
        //no fill
    }

    protected changeSelection(selectionId) {

        //this.menu.activateLoading();

        //
        // update the global value of the selected brand
        this.getVnAppZone().addGlobal({index: "idBrand", value: selectionId});

        //
        //update the menu item list to use the idBrand in the url
        this.menu.setMenus(this.updateMenuListItemWithBrandSelection(selectionId));


        let _this = this;
        setTimeout(function () {
            _this.menu.deactivateLoading();
        }, 500);

        //
        // change the url to the brand specific one /brand/x/
        page("/brand/" + selectionId + "/ads_setups");

    }

    protected updateMenuListItemWithBrandSelection(idBrand) {

        let menus         = this.getAllMenus(idBrand);
        let pathName      = document.location.pathname;
        let topLevelMatch = false;

        for(let x in menus){

            let urls = menus[x].altUrls || [];
            urls.push(menus[x].url);

            urls.forEach(function(value, index){

                if(pathName.includes(value)){

                    topLevelMatch = true;
                }
            });

            // TODO: If we gain more subMenus fix this.
            for(let y in menus[x].subMenus){

                let urls = menus[x].subMenus[y].altUrls || [];
                urls.push(menus[x].subMenus[y].url);

                for(let url of urls){

                    if(pathName.includes(url)){

                        menus[x].selected             = true;
                        menus[x].subMenus[y].selected = true;

                        return menus;
                    }
                }
            }

            if(topLevelMatch){

                menus[x].selected = true;

                return menus;
            }
        }

        return menus;
    }

    protected viewProfile(idUser) {
        page('/users/profile/' + idUser);
    }

    protected setContextBrand() {

        if (this.getVnAppZone().getApp().params.idBrand) {

            let idBrand = this.getVnAppZone().getApp().params.idBrand;
            this.getVnAppZone().addGlobal({index: "idBrand", value: idBrand});
        }else {

            this.getVnAppZone().removeGlobal("idBrand");
        }
    }

    //
    // Hook.
    // Handles on click menu items events.
    public handleMenuItems(value, event){

        let menus = this.getAllMenus(this.getVnAppZone().getGlobal("idBrand") || '');

        for(let x in menus){

            if(value == menus[x].id){

                // page(menus[x].url);
                this.handleNavigation(menus[x].url, event);
            }
        }
    }

    //
    // All possible menus.
    protected getAllMenus(idBrand){

        let showAdsSetups                   = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_SETUP);
        let showAdsTxt                      = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AUTHORIZED_DIGITAL_SELLERS);
        let showSiteConfig                  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SITE_CONFIG);
        let showSspConfigs                  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP_CONFIG);
        let showTemplateTypeDependency      = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_TEMPLATE_TYPE_DEPENDENCY);
        let showZones                       = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ZONE);
        let showAdvertisingPartnersByBrand  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ADVERTISING_PARTNER_BY_BRAND);

        let showAdsManagement = (
            showAdsSetups
            || showAdsTxt
            || showSiteConfig
            || showSspConfigs
            || showTemplateTypeDependency
            || showZones
            || showAdvertisingPartnersByBrand
        );

        let showAdTemplates         = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_TEMPLATE);
        let showAdTypes             = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_TYPE);
        let showBidderTypes         = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BIDDER_TYPE);
        let showContentTypes        = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_CONTENT_TYPE);
        let showGeos                = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_GEO);
        let showGeoGroups           = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_GEO_GROUP);
        let showNetworkCategories   = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_NETWORK_CATEGORY);
        let showSources             = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SOURCE);
        let showSsps                = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP);
        let showSspGroups           = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP_GROUP);
        let showTemplates           = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_TEMPLATE);
        let showTemplateTypes       = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_TEMPLATE_TYPE);
        let showPrebidSspSetupAdd   = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_PREBID_SSP_SETUP_ADD);
        let showGlobalCss           = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_GLOBAL_CSS);
        let showPrebidConfigurator  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_PREBID_CONFIGURATOR);
        let showAdDisplayType       = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_DISPLAY_TYPE);
        let showAdvertisingPartners = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ADVERTISING_PARTNER);
        let showBrands              = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BRANDS_ALL);
        let showAdsBehaviour        = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ADS_BEHAVIOUR);
        let showVideoPrebidFloors   = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_VIDEO_PREBID_FLOOR);
        let showGAMAccounts         = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_GAM_ACCOUNT);
        let showSponsoredRevenue    = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SPONSORED_REVENUE);
        let showConnectionConfig    = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_CONNECTION_CONFIG);
        let showAdsInjectorConfig   = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ADS_INJECTOR_CONFIGS);

        let showConfigurations = (
            showAdTemplates
            || showAdTypes
            || showBidderTypes
            || showContentTypes
            || showGeos
            || showGeoGroups
            || showNetworkCategories
            || showSources
            || showSsps
            || showSspGroups
            || showTemplates
            || showTemplateTypes
            || showPrebidSspSetupAdd
            || showGlobalCss
            || showPrebidConfigurator
            || showAdDisplayType
            || showAdvertisingPartners
            || showBrands
            || showAdsBehaviour
            || showVideoPrebidFloors
            || showGAMAccounts
            || showSponsoredRevenue
            || showConnectionConfig
            || showAdsInjectorConfig
        );

        let showAdHealthReport     = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_HEALTH);
        let showDataImport         = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_DATA_IMPORT);
        let showPerformanceSummary = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_PERFORMANCE_SUMMARY);
        let showRealTimeStats      = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_REAL_TIME_STATS);
        let showArticleLevelSV     = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ARTICLE_LEVEL_SV);
        let showAdRevenue          = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_REVENUE);
        let showRevenueSheet       = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_REVENUE_SHEET);

        let showReports = (
            showAdHealthReport
            || showDataImport
            || showPerformanceSummary
            || showArticleLevelSV
            || showRealTimeStats
            || showAdRevenue
            || showRevenueSheet
        );

        return [
            {
                id: 100,
                text: "ads management",
                url: "/brand/" + idBrand + "/ads_setups",
                altUrls: [
                    "/brand/" + idBrand + "/ad_units",
                    "/brand/" + idBrand + "/home",
                ],
                subMenus: [
                    {
                        id: 101,
                        text: "ads setups",
                        url: "/brand/" + idBrand + "/ads_setups",
                        altUrls: [
                            "/brand/" + idBrand + "/ad_units",
                            "/brand/" + idBrand + "/home",
                        ],
                        subMenus: [],
                        cssClass: ["i-ads"],
                        selected: false,
                        visible: showAdsSetups,
                    },
                    {
                        id: 105,
                        text: "ads.txt",
                        url: `/brand/${idBrand}/ads.txt`,
                        subMenus: [],
                        cssClass: ["i-txt"],
                        selected: false,
                        visible: showAdsTxt,
                    },
                    {
                        id: 109,
                        text: "bidder type library",
                        url: `/brand/${idBrand}/bidder_type_library`,
                        subMenus: [],
                        cssClass: ["i-bidder"],
                        selected: false,
                        visible: showSiteConfig,
                    },
                    {
                        id: 102,
                        text: "configs",
                        url: `/brand/${idBrand}/site_config`,
                        subMenus: [],
                        cssClass: ["i-config"],
                        selected: false,
                        visible: showSiteConfig,
                    },
                    {
                        id: 110,
                        text: "Video Player Stats",
                        url: `/brand/${idBrand}/video_player_stats`,
                        subMenus: [],
                        cssClass: ["i-video"],
                        selected: false,
                        visible: showRealTimeStats,
                    },
                    {
                        id: 107,
                        text: "Repeatable Ad Injection Content to Ignore",
                        url: `/brand/${idBrand}/repeatableAdInjectionContentToIgnore`,
                        subMenus: [],
                        cssClass: ["i-unit-header"],
                        selected: false,
                    },
                    {
                        id: 106,
                        text: "SSP Configs",
                        url: `/brand/${idBrand}/ssps`,
                        subMenus: [],
                        cssClass: ["i-ssp"],
                        selected: false,
                        visible: showSspConfigs,
                    },
                    {
                        id: 104,
                        text: "template type dependencies",
                        url: `/brand/${idBrand}/template_type_dependencies`,
                        subMenus: [],
                        cssClass: ["i-dependencies"],
                        selected: false,
                        visible: showTemplateTypeDependency,
                    },
                    {
                        id: 208,
                        text: "zones",
                        url: `/brand/${idBrand}/zones`,
                        subMenus: [],
                        cssClass: ["i-ad_template_2"],
                        selected: false,
                        visible: showZones,                    },
                    {
                        id: 208,
                        text: "Advertising partners",
                        url: `/brand/${idBrand}/advertising_partners`,
                        subMenus: [],
                        cssClass: ["i-partnership"],
                        selected: false,
                        visible: showAdvertisingPartnersByBrand,
                    },                                        
                    {
                        id: 209,
                        text: "Ads behaviour",
                        url: `/brand/${idBrand}/ads_behaviour`,
                        subMenus: [],
                        cssClass: ["i-behaviour"],
                        selected: false,
                        visible: showAdsBehaviour,
                    },                      
                    {
                        id: 211,
                        text: "Video Prebid Floors",
                        url: `/brand/${idBrand}/video_prebid_floors`,
                        subMenus: [],
                        cssClass: ["i-video"],
                        selected: false,
                        visible: showVideoPrebidFloors,
                    },                             
                    {
                        id: 210,
                        text: "Connection Config",
                        url: `/brand/${idBrand}/connection_config`,
                        subMenus: [],
                        cssClass: ["i-connection"],
                        selected: false,
                        visible: showConnectionConfig,
                    },      
                    {
                        id: 108,
                        text: "A/B Testing Config",
                        url: `/brand/${idBrand}/abTestingSchedule`,
                        subMenus: [],
                        cssClass: ["i-ab-testing"],
                        selected: false,
                        visible: showConnectionConfig,
                    },                         
                    {
                        id: 112,
                        text: "Ads Injector configs",
                        url: `/brand/${idBrand}/ads_injector_configs`,
                        subMenus: [],
                        cssClass: ["i-inject"],
                        selected: false,
                        visible: showAdsInjectorConfig,
                    },                    
                ],
                cssClass: ["i-ads"],
                selected: false,
                visible: showAdsManagement,
            },
            {
                id: 200,
                text: "configurations",
                url: "/configurations",
                altUrls: [],
                subMenus: [
                    {
                        id: 205,
                        text: "Ad Templates",
                        url: "/ad_templates",
                        subMenus: [],
                        cssClass: ["i-ad_template_2"],
                        selected: false,
                        visible: showAdTemplates,
                    },
                    {
                        id: 216,
                        text: "Ads Setup Tags",
                        url: "/ads_setup_tag",
                        subMenus: [],
                        cssClass: ["i-cat"],
                        selected: false,
                        visible: showAdTemplates,
                    },
                    {
                        id: 204,
                        text: "Ad Types",
                        url: "/ad_types",
                        subMenus: [],
                        cssClass: ["i-network"],
                        selected: false,
                        visible: showAdTypes,
                    },
                    {
                        id: 204,
                        text: "Ad Display Type",
                        url: "/ad_display_type",
                        subMenus: [],
                        cssClass: ["i-ad_type_2"],
                        selected: false,
                        visible: showAdDisplayType,
                    },
                    {
                        id: 203,
                        text: "Bidder Types",
                        url: "/bidder_types",
                        subMenus: [],
                        cssClass: ["i-bidder"],
                        selected: false,
                        visible: showBidderTypes,
                    },
                    {
                        id: 208,
                        text: "Content Types",
                        url: "/content_types",
                        subMenus: [],
                        cssClass: ["i-content"],
                        selected: false,
                        visible: showContentTypes,
                    },
                    {
                        id: 210,
                        text: "Geos",
                        url: "/geos",
                        subMenus: [],
                        cssClass: ["i-geo"],
                        selected: false,
                        visible: showGeos,
                    },
                    {
                        id: 211,
                        text: "Geo Groups",
                        url: "/geo_groups",
                        subMenus: [],
                        cssClass: ["i-geogroup"],
                        selected: false,
                        visible: showGeoGroups,
                    },
                    {
                        id: 209,
                        text: "Network Categories",
                        url: "/network_categories",
                        subMenus: [],
                        cssClass: ["i-cat"],
                        selected: false,
                        visible: showNetworkCategories,
                    },
                    {
                        id: 212,
                        text: "Sources",
                        url: "/sources",
                        subMenus: [],
                        cssClass: ["i-data"],
                        selected: false,
                        visible: showSources,
                    },
                    {
                        id: 201,
                        text: "SSPs",
                        url: "/ssps",
                        subMenus: [],
                        cssClass: ["i-ssp"],
                        selected: false,
                        visible: showSsps,
                    },
                    {
                        id: 213,
                        text: "SSP Line Item Upload",
                        url: "/prebid/ssp_setup/add",
                        subMenus: [],
                        cssClass: ["i-url"],
                        selected: false,
                        visible: showPrebidSspSetupAdd,
                    },
                    {
                        id: 206,
                        text: "Templates",
                        url: "/templates",
                        subMenus: [],
                        cssClass: ["i-template"],
                        selected: false,
                        visible: showTemplates,
                    },
                    {
                        id: 207,
                        text: "Template Types",
                        url: "/template_types",
                        subMenus: [],
                        cssClass: ["i-template_types"],
                        selected: false,
                        visible: showTemplateTypes,
                    },
                    {
                        id: 215,
                        text: "Global Css",
                        url: "/global_css/edit",
                        subMenus: [],
                        cssClass: ["i-txt"],
                        selected: false,
                        visible: showGlobalCss,
                    },                    
                    {
                        id: 215,
                        text: "Advertising Partners",
                        url: "/advertising_partners/access",
                        subMenus: [],
                        cssClass: ["i-partnership"],
                        selected: false,
                        visible: showAdvertisingPartners,
                    },
                    {
                        id: 216,
                        text: "Brands",
                        url: "/brand",
                        subMenus: [],
                        cssClass: ["i-config"],
                        selected: false,
                        visible: showBrands,
                    },
                    {
                        id: 217,
                        text: "Ads behaviour",
                        url: "/ads_behaviour",
                        subMenus: [],
                        cssClass: ["i-behaviour"],
                        selected: false,
                        visible: showAdsBehaviour,
                    },
                    {
                        id: 218,
                        text: "GAM accounts",
                        url: "/gam/account",
                        subMenus: [],
                        cssClass: ["i-config"],
                        selected: false,
                        visible:  showGAMAccounts,
                    },
                    {
                        id: 219,
                        text: "Sponsored Revenue",
                        url: "/sponsored_revenue",
                        subMenus: [],
                        cssClass: ["i-config"],
                        selected: false,
                        visible:  showSponsoredRevenue,
                    },
                    {
                        id: 220,
                        text: "Global Connection Config",
                        url: "/connection_config",
                        subMenus: [],
                        cssClass: ["i-config"],
                        selected: false,
                        visible:  showConnectionConfig,
                    }
                ],
                cssClass: ["i-config"],
                selected: false,
                visible: showConfigurations,
            },
            {
                id: 300,
                text: "reports",
                url: "/reports",
                altUrls: [],
                subMenus: [
                    {
                        id: 302,
                        text: "Performance Summary",
                        url: "/performance_summary",
                        subMenus: [],
                        cssClass: ["i-report"],
                        selected: false,
                        visible: showPerformanceSummary,
                    },
                    {
                        id: 301,
                        text: "Ad Health",
                        url: "/ad_health",
                        subMenus: [],
                        cssClass: ["i-health"],
                        selected: false,
                        visible: showAdHealthReport,
                    },
                    {
                        id: 302,
                        text: "Data Import",
                        url: "/data_import",
                        subMenus: [],
                        cssClass: ["i-data"],
                        selected: false,
                        visible: showDataImport,
                    },
                    {
                        id: 304,
                        text: "Video Player Stats",
                        url: `/video_player_stats`,
                        subMenus: [],
                        cssClass: ["i-video"],
                        selected: false,
                        visible: showRealTimeStats,
                    },
                    {
                        id: 305,
                        text: "Content RPM Tool",
                        url: `/article_level_sv`,
                        subMenus: [],
                        cssClass: ["i-report"],
                        selected: false,
                        visible: showArticleLevelSV,
                    },
                    {
                        id: 306,
                        text: "Ad Revenue",
                        url: `/ad_revenue`,
                        subMenus: [],
                        cssClass: ["i-ads"],
                        selected: false,
                        visible: showAdRevenue,
                    },                    
                    {
                        id: 307,
                        text: "Revenue Sheet",
                        url: `/revenue_sheet`,
                        subMenus: [],
                        cssClass: ["i-payment"],
                        selected: false,
                        visible: showRevenueSheet,
                    },
                ],
                cssClass: ["i-report"],
                selected: false,
                visible: showReports,
            },
        ];
    }

    //
    // Gets value of toggle.
    protected getShowMenuSidebar(){

        return this.getVnAppZone().getGlobal('showMenuSidebar');
    }

    //
    // Sets value of toggle.
    protected setShowMenuSidebar(value){

        this.getVnAppZone().addGlobal({index: 'showMenuSidebar', value: value});
    }
}
